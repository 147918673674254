import React, { useMemo } from 'react';

import { getClientProps } from 'src/components/events-list/client-props';
import EventsList from 'src/components/events-list/events-list';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const EventsPage: React.FC = () => {
  const clientProps = useMemo(() => getClientProps()?.eventsPage ?? {}, []);
  return (
    <Layout>
      <SEO title="Events" />
      <PrivateRoute>
        <EventsList {...clientProps} />
      </PrivateRoute>
    </Layout>
  );
};

export default EventsPage;
