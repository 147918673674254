import { OrgProfileReferrer } from 'src/components/organisations/types';
import { getDefaultColumnsConfig } from 'src/components/scheme-courses-list/config';

export const defaultTransKeys = {
  title: 'all events',
  queryError: 'organisationsModule.queryErrors.getOrgEvents',
};

export const defaultFiltersConfig = {
  hiddenStatusFilterOptions: [],
  categoryFilter: {
    hiddenStatusFilterOptions: ['restricted'],
    schemeCourseTransKey: 'event',
    defaultLabelTransKey: 'any event',
  },
  regionFilter: {
    transKeyDefaultOption: 'any approver',
  },
};

export const defaultColumnsConfig = getDefaultColumnsConfig({
  orgProfileReferrer: OrgProfileReferrer.EVENTS,
  nameColumnHeaderTransKey: 'event',
  disabledColumns: ['Scheme.MinimumAge', 'Scheme.MaximumAge', 'LeadCoach'],
});
